<div *ngIf="display">
    <header class="header header-6 theme-container" fxHide fxShow.gt-md style="position: fixed;z-index: 10;">
        <div class="header-top" style="background-color: #F5F5F5;">
            <div [class]="containerClass">
                <!-- <div (click)="composeEmail(data.smtpUsername)" class="header-left" style="cursor: pointer;">
                    <mat-icon  class="icon-css">mail</mat-icon>{{data.smtpUsername}}
                </div> -->

                <div fxFlex="5">

                </div>

                <!-- <div class="header-left" style="cursor: pointer;">
                    {{data.headerNotificationContent}}
                </div> -->

                <div class="header-right">
                    <!-- <div *ngIf="showFooterIcon==1">
                        <div>
                            <span *ngFor="let item of footerIconData; let i = index">

                                <button (click)="navigate(item)" mat-icon-button>

                                    <mat-icon [svgIcon]="item.iconName"> </mat-icon>
                                </button>
                            </span>

                        </div>

                    </div> -->

                    <!-- <div class="login padding-10" style="font-size:12px" *ngIf="languageSelection==1">
                        <calsoft-language></calsoft-language>
                    </div> -->

                    <div style="cursor:pointer ;font-size: 12px;" fxLayoutAlign="center center">
                        <div *ngIf="data.disableLoginHeader==0">
                        <div class="login padding-10" *ngIf="!userLoggedIn" (click)="SignIn('login')">
                            <p style="font-size: 14px;">Login</p>
                        </div>
                        </div>

                        <calsoft-HeaderUserProfileDropdown [data]="data" [color]="'white'"
                            *ngIf="userLoggedIn"></calsoft-HeaderUserProfileDropdown>

                    </div>


                </div>
            </div>
        </div>

        <div class="header-middle">
            <div class="containerCss">
                    <div fxFlex="1"></div>
                    <div fxFlex="40" fxLayoutAlign="center center">                    
                        <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="list1"></calsoft-Menu>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="10">                    
                        <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                    </div>
                    <div fxFlex="2"></div>
                    <div fxFlex="40" fxLayoutAlign="center center">                        
                        <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="list2"></calsoft-Menu>
                    </div>
                    <div fxFlex="5">
                        <calsoft-book-popup></calsoft-book-popup>
                    </div>
            </div>
        </div>

        <!-- <mat-toolbar fxHide fxShow.md fxShow.gt-md class="calsoft-fixed-header primary" fxLayout="row"
            fxLayoutAlign="space-between center" style="z-index: 1000;">
            <mat-toolbar-row class=" theme-container" style="height: 100%;" fxLayoutAlign="space-between center">
                <div class="header-middle">
                    <div class="containerCss">
                            <div fxFlex="3"></div>
                            <div fxFlex="35" >                    
                                <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="list1"></calsoft-Menu>
                            </div>
                            <div fxFlex="2"></div>
                            <div fxFlex="10">                    
                                <calsoft-logo [sitename]="data.siteName" [type]="'light'"></calsoft-logo>
                            </div>
                            <div fxFlex="2"></div>
                            <div fxFlex="35" >                        
                                <calsoft-Menu [data]="data" style="z-index: 1000;" [type]="'one'" [categoryArray]="list2"></calsoft-Menu>
                            </div>
                            <div fxFlex="3"></div>

                            <div fxFlex="10" >                        
                            <button *ngIf="!userLoggedIn" (click)="SignIn('login')" style="line-height: 15px" mat-button>
                                <div style="color: #ffffff" fxLayout="column">
                                    <small>Hello, Sign in</small>
                                    <span>Account & Lists</span>
                                </div>
                            </button>
        
                            <calsoft-HeaderUserProfileDropdown [data]="data" [type]="scroll" class="headerUser"
                                *ngIf="userLoggedIn">
                            </calsoft-HeaderUserProfileDropdown>
                        </div>

                    </div>
                </div>
        
            </mat-toolbar-row>

        </mat-toolbar> -->
    </header>
</div>
<div *ngIf="!display">

    <div *ngIf="mobileHeaderType==1">
        <calsoft-mobile-header [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header>
    </div>

    <div *ngIf="mobileHeaderType==0">
        <calsoft-mobile-header-new [data]="data" [color]="'white'" [url]="url" [userLoggedIn]="userLoggedIn">

        </calsoft-mobile-header-new>
    </div>

    <div *ngIf="mobileHeaderType==2">
        <calsoft-mobile-header3  [data]="data" [color]="'white'" [url]="url" 
        [userLoggedIn]="userLoggedIn">
    
        </calsoft-mobile-header3>
        </div>

</div>